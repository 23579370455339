import React from "react"
import { graphql } from "gatsby"

import { Seo } from "../components"

import { Box, Text } from "@chakra-ui/react"

import { Paragraph } from "../sections/articles/Content"

import { ScrollIcon } from "../sections/articles/ArticleHeader"

const Title = ({ title }) => (
  <Box mt={12} mb={12} px={{ base: null, md: 4 }}>
    <Box pos="relative">
      <Text as="span" fontFamily="CocoSharp-Bold" textTransform="uppercase">
        {title}
      </Text>
      <Box position="absolute" left={{ base: "-15px", md: "-20px" }} top="5px">
        <ScrollIcon />
      </Box>
    </Box>
  </Box>
)

const ContentPage = ({ data }) => {
  const { title, content, uri } = data.page
  return (
    <>
      <Seo
        title={title}
        description={content?.replace(/<[^>]+>/g, "")}
        pathname={uri}
      />
      <Title title={title} />
      <Box
        px={{ base: null, lg: 4 }}
        // pr={{ base: null, lg: 8 }}
        maxW={{ base: null, lg: "900px" }}
        mb={16}
        sx={extraTextStyle}
      >
        <Paragraph text={content} />
      </Box>
      <Box mt={16} />
    </>
  )
}

export default ContentPage

export const query = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      uri
      title
      content
    }
  }
`

const extraTextStyle = {
  h2: {
    marginBottom: "0.5rem !important",
  },
  "h2:not(:first-of-type)": {
    marginTop: "3rem",
  },
}
